<template>
  <div class="slider">
    <splide :options="options">
      <splide-slide
        v-for="slide in images"
        :key="slide.src"
        @click="linkTo(slide.id)"
      >
        <img
          :src="slide.url"
          :alt="slide.alt"
          v-if="slide.url.includes('http')"
          :class="defineHover"
        />
        <div
          class="img"
          :style="{ 'background-image': `url(${files + slide.url})` }"
          :class="defineHover"
          v-else
        ></div>
      </splide-slide>
    </splide>
  </div>
</template>

<script>
  // https://splidejs.com/
  import { Splide, SplideSlide } from '@splidejs/vue-splide'
  import '@splidejs/splide/dist/css/themes/splide-sea-green.min.css'
  import { mapState } from 'vuex'
  import router from '../router'

  export default {
    components: {
      Splide,
      SplideSlide,
    },
    data() {
      return {
        options: {
          rewind: true,
          perPage: this.perPage,
          autoplay: false,
          tabindex: this.indexNum,
          classes: {
            arrows: 'splide__arrows arrow',
            arrow: 'splide__arrow arrow',
            prev: 'splide__arrow--prev arrow',
            next: 'splide__arrow--next arrow',
            // pagination: 'splide__pagination position', // container
            page: 'splide__pagination__page position', // each button
          },
          // gap: '1rem',
          // width: 200,
        },
      }
    },
    props: {
      images: {
        type: Array,
        default: [
          {
            id: 'id',
            url: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/1.png',
            description: 'text',
          },
          {
            id: 'id',
            url: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/2.png',
            description: 'text',
          },
          {
            id: 'id',
            url: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/3.png',
            description: 'text',
          },
          {
            id: 'id',
            url: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/4.png',
            description: 'text',
          },
          {
            id: 'id',
            url: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/5.png',
            description: 'text',
          },
          {
            id: 'id',
            url: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/6.png',
            description: 'text',
          },
          {
            id: 'id',
            url: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/7.png',
            description: 'text',
          },
          {
            id: 'id',
            url: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/1.png',
            description: 'text',
          },
          {
            id: 'id',
            url: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/2.png',
            description: 'text',
          },
        ],
      },
      type: {
        type: String,
        default: '',
      },
      perPage: {
        type: Number,
        default: 3,
      },
      indexNum: {
        type: Number,
        default: 0,
      },
      link: {
        type: Boolean,
        default: true,
      },
      hover: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapState(['files']),
      defineURL() {
        let url = ''
        if (this.type == 'exhibitor') {
          url = '/exhibitor/'
        } else if (this.type == 'product') {
          url = '/product/'
        }
        return url
      },
      defineHover() {
        return this.hover ? 'mouseHover' : ''
      },
    },
    methods: {
      linkTo(id) {
        if (this.link) {
          router.push(this.defineURL + id)
        }
      },
    },
  }
</script>

<style>
  .slider {
    height: 90%;
  }
  .splide,
  .splide__track,
  .splide__list,
  .splide__slide,
  .splide__slide img,
  .img {
    height: 100%;
  }
  .splide {
    padding-top: 0;
  }
  .splide__arrow {
    top: 40%;
  }
  .slider .img {
    margin: 5px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .slider li {
    text-align: center;
  }
</style>
